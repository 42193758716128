import React from 'react';

import { OuterContainer } from '../OuterContainer.jsx';
import { TextBox } from '../TextBox.jsx';
import { Text } from '../';
import { RowContainer } from './Containers';
import { ObjectWithText } from './Containers';
import { PhoneBox } from '../../components';
import { SectionTemplate, SectionTextContent } from '../';
import styled from 'styled-components';

const BuildPage = () => {
    return (
        <SectionTemplate
            header={
                <SectionTextContent
                    headingText=" What Can You Build?"
                    paragraphText="Using our API & SDK resources, you can build anything! Here are some examples."
                />
            }
        >
            <RowContainer justifyContent="space-between">
                {examples.map((example) => (
                    <ObjectWithText minWidth="147px" width="20%">
                        <Phone />
                        <Text as="h3" color="primary">
                            {example}
                        </Text>
                    </ObjectWithText>
                ))}
            </RowContainer>
        </SectionTemplate>
    );
};

const examples = [
    'Ordering System',
    'Employee Management',
    'Employee Analytics',
];

const Phone = styled(PhoneBox)`
    border: 3px solid #080808;
    width: 100%;
    margin-bottom: 8%;
    margin-top: 8%;
    position: relative;
    padding-top: 170%;
`;

export default BuildPage;
