import React from 'react';
import { FOR_DEVELOPERS } from './Constants';
import { ImageContainer } from '../ImageContainer.jsx';
import { useDeveloperImages } from '../../queries/useDeveloperImages';
import { SectionTemplate } from '../';
import Img from 'gatsby-image';

const ForDevelopers = () => {
    const data = useDeveloperImages().images.nodes;
    return (
        <SectionTemplate
            headingText="For Developers"
            paragraphText="Build new solutions and prototype ideas easily and quickly
            through our UI-Kit (React only) as well as our API & SDK
            resources listed below."
            imageContent={
                <ImageContainer>
                    <Img fluid={data[FOR_DEVELOPERS].childImageSharp.fluid} />
                </ImageContainer>
            }
        />
    );
};

export default ForDevelopers;
