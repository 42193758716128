import React from 'react';
import { DOCUMENTATION } from './Constants';
import { OuterContainer } from '../OuterContainer.jsx';
import { ObjectWithText, CenterItem } from './Containers';
import { ImageContainer } from '../ImageContainer.jsx';
import { RedCircleBorder } from '../RedCircleBorder.jsx';
import { Text } from '../';
import styled from 'styled-components';
import { useDeveloperImages } from '../../queries/useDeveloperImages';
import Img from 'gatsby-image';
import { SectionTemplate } from '../';

const DocumentationPage = () => {
    const data = useDeveloperImages().images.nodes;
    return (
        <SectionTemplate
            header={
                <>
                    <Text as="h1" color="primary" align="center">
                        Documentation
                    </Text>
                    <Text as="p" color="black" align="center">
                        Explore our UI-Kit to integrate CheaprEats.
                    </Text>
                </>
            }
            textComponent={
                <ObjectWithText width="100%">
                    <CenterItem>
                        <UiKitIcon>
                            <Text as="h1" color="primary" align="center">
                                UI-Kit
                            </Text>
                        </UiKitIcon>
                    </CenterItem>
                    <Text as="p" color="black" align="center">
                        (for React Only)
                    </Text>
                </ObjectWithText>
            }
            imageContent={
                <ImageContainer>
                    <Img fluid={data[DOCUMENTATION].childImageSharp.fluid} />
                </ImageContainer>
            }
            reverse
        />
    );
};

const UiKitIcon = styled(RedCircleBorder)`
    flex-basis: 207px;
    padding: 7.5px;
    margin-left: 0;
    border-radius: 20px;
`;

export default DocumentationPage;
