import React from 'react';

import { RESOURCES } from './Constants';

import { OuterContainer } from '../OuterContainer.jsx';
import { RedCircleBorder } from '../RedCircleBorder.jsx';
import { ImageContainer } from '../ImageContainer.jsx';
import { RowContainer, ObjectWithText, CenterItem } from './Containers';
import { TextBox } from '../TextBox.jsx';
import { Text } from '../';
import { SectionTemplate } from '../';
import { useDeveloperImages } from '../../queries/useDeveloperImages';
import Img from 'gatsby-image';

const ResourcesPage = () => {
    const data = useDeveloperImages().images.nodes;
    return (
        <SectionTemplate
            headingText="Resources"
            paragraphText="Explore our API & SDK resources to get started."
            imageContent={
                <ImageContainer>
                    <Img fluid={data[RESOURCES].childImageSharp.fluid} />
                </ImageContainer>
            }
            textComponent={
                <TextBox textAlign="left">
                    <RowContainer>
                        <ObjectWithText width="50%">
                            <CenterItem>
                                <RedCircleBorder>
                                    <Text
                                        as="h1"
                                        color="primary"
                                        size="smaller"
                                    >
                                        API
                                    </Text>
                                </RedCircleBorder>
                            </CenterItem>
                            <Text as="p" color="black" size="small">
                                GraphQL API allows you to access and manipulate
                                data with a more flexible experience.
                            </Text>
                        </ObjectWithText>
                        <ObjectWithText width="50%">
                            <CenterItem>
                                <RedCircleBorder>
                                    <Text
                                        as="h1"
                                        color="primary"
                                        size="smaller"
                                    >
                                        SDK
                                    </Text>
                                </RedCircleBorder>
                            </CenterItem>
                            <Text as="p" color="black" size="small">
                                Our TS-SDK includes a set of tools to develop
                                software applications and allows developers to
                                deploy products quickly.
                            </Text>
                        </ObjectWithText>
                    </RowContainer>
                </TextBox>
            }
        />
    );
};

export default ResourcesPage;
