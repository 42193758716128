import styled from 'styled-components';

const RowContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    ${({ justifyContent }) =>
        justifyContent ? `justify-content: ${justifyContent};` : ''}
    margin-top: 3%;
`;

const ObjectWithText = styled.div`
    display: flex;
    ${({ width }) => (width ? `width: ${width};` : '')}
    ${({ minWidth }) =>
        minWidth ? `min-width: ${minWidth};` : ''}
    flex-direction: column;
    text-align: center;
`;

const CenterItem = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
`;

export { RowContainer, ObjectWithText, CenterItem };
