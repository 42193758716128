import React from 'react';
import ForDevelopers from '../components/developerPages/forDevelopers.jsx';
import WhatCanYouBuild from '../components/developerPages/whatCanYouBuild.jsx';
import Resources from '../components/developerPages/resources.jsx';
import Documentation from '../components/developerPages/documentation.jsx';
import { Layout } from '../components';

const DeveloperPage = () => {
    return (
        <Layout title="Developer">
            <ForDevelopers />
            <WhatCanYouBuild />
            <Resources />
            <Documentation />
        </Layout>
    );
};

export default DeveloperPage;
